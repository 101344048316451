<div id="cartWrapper">
    <div id="cartHeadDiv" class="cartHeadDiv">
        <img [src]="cartImgPath">
        <p id="cartShotCrtLbl" class="lblShop">Your Shopping Bag</p>
        <p id="lblCount">-&nbsp;{{cartNum}}&nbsp;items</p>
        <span id="cartCloseBtn" class="fa fa-remove cartCloseBtn" (click)="onClose()">
        </span>
    </div>
    <div *ngIf="emptyCart" id="cartEmptyDiv" class="cartEmptyDiv">
        <div id="cartEmptyDivInner" class="cartEmptyDivInner content-center">
            <p id="lblEmptyBag" class="lblEmptyBag">Your Bag is Empty!!</p>
            <a (click)="onClose()" id="lnkGoShopping" class="lnkGoShopping">
                <img [src]="goShoppingPath">
            </a>
        </div>
    </div>
    <div *ngIf="!emptyCart" id="cartTblTitlDiv" class="cartTblTitlDiv">
        <p class="col-xs-4 cartTblTitlLbl">Product Description</p>
        <p class="col-xs-2 cartTblTitlLbl">Unit Price</p>
        <p class="col-xs-1 cartTblTitlLbl">Quantity</p>
        <p class="col-xs-2 cartTblTitlLbl">Delivery</p>
        <p class="col-xs-2 cartTblTitlLbl">Sub Total</p>
    </div>
    <div *ngIf="!emptyCart" class="cartItemItrDiv">
        <div *ngFor="let cartProduct of cartProducts; let cartProdIdx = index;" class="cartItmDetlsDiv">
            <div class="cartItmContainer" *ngIf="!cartProduct?.offer">
                <div class="productImageContainer col-xs-4">
                    <div class="imageDiv col-xs-4">
                        <a [routerLink]="goToProductDetailPage(cartProduct?.productModel)" [queryParams]="getQueryParamsForProduct(cartProduct?.productModel)" class="imageLink" (click)="onClose()">
                            <img class="productImage img-responsive center-position" [src]="(cartProduct?.productModel?.imageUrl && cartProduct?.productModel?.images && cartProduct?.productModel?.images[0])? 
                            cartProduct?.productModel?.imageUrl +'m/'+ cartProduct?.productModel?.images[0]:defaultPath"
                                alt="" (error)="imgErrorHandler($event)">
                        </a>
                    </div>
                    <div class="detailsDiv col-xs-8">
                        <a id="cartItemTtl" class="cartItemTtl " title="cartProduct.productModel.productName">{{cartProduct?.productModel?.productName}}</a>
                        <a [routerLink]="'/author/'+ cartProduct?.productModel?.brandUrl" (click)="onClose()" id="author">{{cartProduct?.productModel?.brandName}}</a>
                        <div class="variantWrapper">
                            <p *ngIf="cartProduct.productModel.variantAttributes != undefined" class="variant">{{setVariantName(cartProduct?.productModel?.variantAttributes)}}</p>
                        </div>
                        <a id="lnkWishList" class="lnkWishList col-xs-1 noPadding hide" title="Add to Wishlist">
                            <span id="imgWishList" class="fa fa-heart-o"></span>
                        </a>
                    </div>
                </div>
                <div class='productPrice col-xs-2'>
                    <div class="priceDiv content-center">
                        <p id="productSalePrice">{{cartProduct?.productModel?.salePrice | currency:currType:'symbol':
                            priceRoundOffInteger}}</p>
                        <p id="productMrp" *ngIf="cartProduct.productModel.mrp > cartProduct.productModel.salePrice">
                            {{cartProduct?.productModel?.mrp | currency:currType:'symbol': priceRoundOffInteger}}
                        </p>
                    </div>
                </div>
                <div class="productQuantity col-xs-1">
                    <div class="content-center">
                        <div fxLayoutAlign="center center" class="pnlSpinner">
                            <div fxLayout="row" class="spinner">
                                <div class="firstDiv">
                                    <input value="{{cartProduct.numCopies}}" disabled />
                                </div>
                                <div fxLayout="column" class="secondDiv">
                                    <div fxLayoutAlign="center center" class="prodQuantitySpinnerUp fa fa-chevron-up"
                                        (click)="increaseProQuantity(cartProduct, cartProdIdx)"></div>
                                    <div fxLayoutAlign="center center" class="prodQuantitySpinnerDown  fa fa-chevron-down"
                                        (click)="decreaseProQuantity(cartProduct, cartProdIdx)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="deliveryPnl col-xs-2">
                    <div class="deliverContainer content-center">
                        <p>{{cartProduct?.productModel?.shippingCharges | currency:currType:'symbol':
                            priceRoundOffInteger}}</p>
                        <p *ngIf="cartProduct?.productModel?.shippingDays > 0">{{cartProduct?.productModel?.shippingDays}}-{{getAdditionalShippingDays(cartProduct?.productModel?.shippingDays)}}&nbsp;days</p>
                        <p *ngIf="!(cartProduct?.productModel?.shippingDays > 0)">{{shippingDays}}-{{getAdditionalShippingDays(shippingDays)}}&nbsp;days</p>
                    </div>
                </div>
                <div class="subTotalPnl col-xs-2">
                    <p class="content-center">{{calculateSubTotal(cartProduct) | currency:currType:'symbol':
                        priceRoundOffInteger}}</p>
                </div>
                <div class="cartDelete col-xs-1" title="Delete">
                    <span (click)='removeProduct(cartProduct, cartProdIdx)' class="cartDeleteIcon content-center">Delete</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!emptyCart" class="cartGrandTotalDiv">
        <div class="orderSmryPnl col-xs-6">
            <p>Order Summary</p>
        </div>
        <div class="couponAmount" *ngIf="couponApplied">
            <p class="couponDesc">{{couponApplied}} Coupon Applied</p>
            <span id="text">Coupon Amount:</span>
            <span id="value">{{couponAmount | currency:currType}}</span>
        </div>
        <div *ngIf="shippingCharges > 0" class="shippingPanel hide">
            <span id="text">Shipping Charges:</span>
            <span id="value">{{shippingCharges| currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
        <div *ngIf="handlingCharges > 0" class="handlingPanel hide">
            <span id="text">Handling Charges:</span>
            <span id="value">{{handlingCharges| currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
        <div class="taxesPanel hide">
            <span id="text">GST Charges:</span>
            <span id="value">{{taxes| currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
        <div class="totalSavings hide">
            <span id="text">Total Savings:</span>
            <span id="value">{{totalSavings | currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
        <div class="grandTotal col-xs-4">
            <span id="text"> Grand Total:</span>
            <span id="value">{{grandTotal | currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
    </div>
    <div *ngIf="!emptyCart" class="cartFooterDiv">
        <a class="shopMore" (click)="onClose()">Shop More</a>
        <a class="contactUs" (click)="openContactUs()">Contact Us</a>
        <a (click)="proceedToCheckout()" class="checkout">Proceed</a>
    </div>
</div>