<div fxLayout="column" class="main-container" [ngClass]="parentClass">
  <div fxLayout="row" class="collectionPart">
    <ng-container *ngIf="!(searchResultsPage || isCollectionUrl) && filterList && filterList.length > 0">
      <div class="filterParent" [ngClass]="{'hideFilters': hideFilters, 'showFilters': !hideFilters}" [ngClass]="{'showFilters': _showFilters, 'hideFilters':_showFilters }"
        [style.top]="filtetTop+'px'">
        <div>
          <app-filters (onSelectingFilter)="updateCollection($event)" (applyFilterClose)="hideFiltersPanel()"
            [filter-list]="filterList"></app-filters>
        </div>
      </div>
    </ng-container>
    <div fxLayout="column" class="collectionParent" [ngClass]="{'padding-none': (searchResultsPage || isCollectionUrl)}">
      <div class="selectedFiltersPanel" *ngIf="FilterModel != undefined && FilterModel.length > 0">
        <h4 class="showResultsHeading">Showing Results in : </h4>
        <div class="filterTagsPanel">
          <ul class="filterTypeIterator">
            <li *ngFor="let filter of FilterModel; let i=index;">
              <div class="selFilterTypePanel" *ngIf=" filter.filterModelList != undefined && filter.filterModelList.length > 0">
                <p class="filterTypeLabel">{{filter.filterName}} : </p>
                <ul class="filterNameIterator">
                  <ls-tag-container (tag-removed)="remove($event)" [tags]="filter?.filterModelList"></ls-tag-container>
                </ul>
              </div>
            </li>
          </ul>
          <div class="clearAllPanel" (click)="clearAllFilters()" *ngIf="FilterModel.length > 0">
            <span class="crossIcon fa fa-close"></span>
            <p class="clearAllLabel">Clear All Filters</p>
          </div>
        </div>
      </div>
      <div class="title" *ngIf="!errorPage">
        <h2 *ngIf="!searchResultsPage">{{pageTitle}}</h2>
        <h2 *ngIf="searchResultsPage && !showError">Search Products for</h2>
        <span *ngIf="searchResultsPage && !showError">"{{searchKey}}"</span>
        <span *ngIf="!searchResultsPage" class="fa fa-lg fa-filter filter-span filter-icon" (click)="showFilters()"></span>
        <div fxLayout="row" class="gridListView" *ngIf="!errorPage">
          <div *ngIf="!list_View" class="fa fa-align-justify list" (click)="listView()"></div>
          <div *ngIf="list_View" class="fa fa-th grid" (click)="gridView()"></div>
        </div>
        <div class="line"></div>
      </div>
      <div class="tileDiv">
        <div *ngFor="let product of products; let i = index;" class="tile-container" [ngClass]="{'list-view':list_View, 'disabled': !(product?.preOrder && product?.releaseDate && product?.releaseDate > todaysDate) && product?.outOfStock == true}">
          <app-product-tile [tile-data]="product" [view]="listViewStr" (addToCartWishlist)="addToWishlist($event)"
            (addToCart)="addToCartProduct($event)"></app-product-tile>
        </div>
      </div>
      <div class="pnlShowMore" *ngIf="!errorPage" [ngClass]="{'show':hasMore}" (click)="showMore()">
        <a class="lnkShowMore">Show More Products</a>
      </div>
      <div class="pnlNoResultOuter" *ngIf="errorPage">
        <div class="pnlNoResultInner">
          <p class="lblNoResult">Couldn't find what you were looking for?</p>
          <p class="noResultLbl2">Just send us detail of the product and we will update you.</p>
          <a (click)="getForMe()">Click Here</a>
        </div>
      </div>
    </div>
  </div>
  <div class="seoContent" *ngIf="content != undefined && content != 'false' && showSeo">
    <div [innerHtml]='content | safeHtml' class="static-content"></div>
  </div>
  <div *ngIf="showError" class="no-results">
    <div class="pnlNoResultInner">
      <p class="lblNoResult">Couldn't find what you were looking for?</p>
      <p class="noResultLbl2">Just send us detail of the product and we will update you.</p>
      <a (click)="getForMe()">Click Here</a>
    </div>
  </div>
  <div fxLayoutAlign="center center" class="errorPage" *ngIf="parentClass == 'showErrorPage'">
    <img [src]="errorPageUrl" style="max-width:100%; max-height:100%;">
  </div>
</div>