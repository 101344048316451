import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';

import { CustomerSurveyComponent } from '../../commonComponent/customer-survey/customer-survey.component'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service'
import { CartCountService } from '../../../services/cart-count.service'
import { CustomerSurveyService } from '../../../services/customerSurvey.service'
import { AnswerModel } from '../../../common/models/answerModel';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-order-cancelled',
  templateUrl: './order-cancelled.component.html',
  styleUrls: ['./order-cancelled.component.scss']
})
export class OrderCancelledComponent implements OnInit {

  formId: number;
  storeName = Constants.WAR_NAME;

  constructor(
    public customerSurveyDialogService: LsDialogService<CustomerSurveyComponent>,
    private cartCountService: CartCountService,
    private customerSurveyService: CustomerSurveyService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {

  }

  openSurvey() {
    let data: Array<{ formId: number }> = []
    let surveyFormRequestData = Object
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.customerSurveyService.getSurveyForm(resp => {
          if (!resp.error) {
            if (resp.data != undefined && resp.data != null && resp.data.formId != undefined && resp.data.formId != null)
              this.formId = resp.data.formId
            data.push({
              formId: this.formId
            })
            this.customerSurveyDialogService.open(CustomerSurveyComponent, {}, data).subscribe((response) => {
              let answerModel = new AnswerModel()
              answerModel.formId = this.formId
              let isSurveyFormFilled = false
              this.cartCountService.surveyFormFillObservable.subscribe((flag) => {
                isSurveyFormFilled = flag
              })
              if (!isSurveyFormFilled) {
                this.customerSurveyService.submitQuestionsForSurvey(answerModel, (resp) => {
                })
              }
            })
          }
        })
      }, 1000);
    }
  }

}
