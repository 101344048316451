<!-- <p class="heading" fxShow.lt-sm fxHide.gt-xs>Track Order</p> -->

<div class="order-track dashboardBdy" *ngIf="order != undefined">
  <div fxLayout="column" class="pnlTrackOrderBody">
    <div fxLayout="row" class="pnlDetailsTrackOrder">
      <div fxLayout="column" class="pnlOrderDetailsOuter trackOrderDetailsPanels">
        <div fxLayout="row">
          <p class="lblOrderTrackId">Order ID</p>
          <p [innerHTML]>{{order.orderDisplayId}}</p>
        </div>
        <p class="dateLblForOrderTrack">{{order.orderDate | date:'dd/MM/yyyy'}}</p>
        <p class="vendorLblOrderTrack boldLbl"></p>
      </div>
      <div class="trackOrderDetailsPanels pricePnl">
        <p>{{order.amount | currency:currType:'symbol': priceRoundOffInteger}}</p>
      </div>
      <div fxLayout="row" class="trackOrderDetailsPanels quantityPnl">
        <p>Qty</p>
        <p class="redLbl">{{order.orderDetailModels.length}}</p>
      </div>
      <div class="pnlCancelAllBtn trackOrderDetailsPanels">
        <p class="otherStatus" [ngClass]="{'redText': order.orderStatus == const.ORDER_STATUS.CANCELLED ,'greenStatus' : order.orderStatus == const.ORDER_STATUS.COMPLETED }">{{order.orderStatusStr}}</p>
      </div>
      <div fxLayout="column" class="btnPnl">
        <!-- <div fxLayoutAlign="center center" class="pnlTrackBtn" (click)="toggleShowDetails(i)">
              <a class="lnkTrackBtn">Details</a>
            </div> -->
        <!-- <div fxLayoutAlign="center center" class="pnlCancelOrderBtn" (click)="cancelAll()" *ngIf="order.orderStatus == const.ORDER_STATUS.RECEIVED">
              <a class="lnkTrackOrderPopoverBtn">Cancel All</a>
            </div> -->
      </div>
    </div>

    <!-- <div class="mobile-header" fxLayout="column" fxShow.lt-sm fxHide.gt-xs>
      <div class="header-labels" fxLayout="row">
        <p fxFlex="20">Order ID</p>
        <p fxFlex="20">Price</p>
        <p fxFlex="20">Qty</p>
        <p fxFlex="20">Status</p>
        <p fxFlex="20">Amount</p>
      </div>
      <div class="header-values" fxLayout="row">
        <div fxLayout="column" fxFlex="20">
          <p style="font-size:13px;">{{order.orderDisplayId}}</p>
          <p style="font-size:12px;color:#999;" class="dateLblForOrderTrack">{{order.orderDate | date:'dd/MM/yyyy'}}</p>
        </div>
        <p style="font-size:13px;" fxFlex="20">{{order.amount | currency:currType:'symbol': priceRoundOffInteger}}</p>
        <p style="font-size:13px;" fxFlex="20" class="redLbl">{{order.orderDetailModels.length}}</p>
        <p style="font-size:13px;" fxFlex="20" class="otherStatus" [ngClass]="{'redText': order.orderStatus == const.ORDER_STATUS.CANCELLED ,'greenStatus' : order.orderStatus == const.ORDER_STATUS.COMPLETED }">{{order.orderStatusStr}}</p>
        <p style="font-size:13px;" fxFlex="20">{{order.amount | currency:currType:'symbol': priceRoundOffInteger}}</p>

      </div>
    </div> -->
    <div class="pnlOrderDetailsTrackOrder">
      <div fxLayout="row" class="tabs-headers">
        <p class="leftPnl tab-selected" (click)="leftClick()" [ngClass]="{'tab-selected': showLeft}">Detail</p>
        <p class="rightPnl" (click)="rightClick()" [ngClass]="{'tab-selected': showRight}">Shipping Address</p>
      </div>
      <div class="tabPnl" [ngClass]="{'hide': !showLeft}">
        <div class="tabBody">
          <div fxLayout="column" class="pnlDetailBdy">
            <div fxLayoutAlign="start center" class="pnlOrderDetailHeading">
              <p>Order Details</p>
            </div>
            <div class="orderDtlsItr">
              <div class="top-padding" fxLayout="column" *ngFor="let orderModel of order.orderDetailModels">
                <div fxLayout="row" class="pnlOrderDetailBdy">
                  <a class="pnlOrderImg">
                    <img [src]="(orderModel?.productModel?.imageUrl && orderModel?.productModel?.images && orderModel?.productModel?.images[0])? orderModel?.productModel?.imageUrl +'m/'+ orderModel?.productModel?.images[0] : defaultPath"
                      class="center-position" (error)="imgErrorHandler($event)">
                  </a>
                  <div class="pnlOrderDtls">
                    <div class="pnlOrderDtlsTitle">
                      <p>{{orderModel.productModel.productName}}</p>
                    </div>
                    <div fxLayout="row" class="pnlOrderDetailsBrand">
                      <div class="pnlOrderDetailsBrandInner"></div>
                      <div fxLayout="row" class="pnlOrderDetailsQty">
                        <p class="label">Quantity</p>
                        <p class="value">{{orderModel.numCopies}}</p>
                      </div>
                    </div>
                    <div fxLayout="row" class="pnlOrderDtlsPrice">
                      <p class="label">Price</p>
                      <p class="value">{{orderModel.amount | currency:currType}}</p>
                    </div>
                    <div fxLayout="row" class="pnlOrderDtlsStatus">
                      <p class="label">Status</p>
                      <p class="value">{{orderModel.orderStatusStr}}</p>
                    </div>
                    <div class="variantItr">
                      <div fxLayout="row" *ngIf="orderModel.variant1">
                        <p class="label">{{orderModel.variant1}}</p>
                        <p class="value">{{orderModel.variantValue1}}</p>
                      </div>
                      <div fxLayout="row" *ngIf="orderModel.variant1">
                        <p class="label">{{orderModel.variant2}}</p>
                        <p class="value">{{orderModel.variantValue2}}</p>
                      </div>
                      <div fxLayout="row" *ngIf="orderModel.variant1">
                        <p class="label">{{orderModel.variant3}}</p>
                        <p class="value">{{orderModel.variantValue3}}</p>
                      </div>
                    </div>
                    <!-- <div class="pnlOrderDetailBtns hide" fxShow.lt-sm fxHide.gt-xs>
                            <div fxLayoutAlign="center center" class="pnlQueryBtnOrderDetail" (click)="query(order.orderId, orderModel.productModel)">
                              <a>Query</a>
                            </div>
                            <div fxLayoutAlign="center center" class="pnlCancelBtnOrderDetail" *ngIf="order.orderStatus == const.ORDER_STATUS.RECEIVED">
                              <a>Cancel</a>
                            </div>
                            <div fxLayoutAlign="center center" class="pnlReturnBtnOrderDetail" *ngIf="(order.orderStatus == const.ORDER_STATUS.COMPLETED) && (orderModel.allowReturn)">
                              <a>Return</a>
                            </div>
                            <div fxLayoutAlign="center center" class="pnlRefundBtnOrderDetail" *ngIf="order.orderStatus == const.ORDER_STATUS.COMPLETED && orderModel.allowReturn">
                              <a>Exchange</a>
                            </div>
                            <a class="printInvoiceLnk fa fa-print"></a>
                            <a class="emailInvoiceLnk fa fa-envelope"></a>
                          </div> -->
                  </div>
                  <div class="pnlOrderDetailBtns">
                    <div fxLayoutAlign="center center" class="pnlQueryBtnOrderDetail" (click)="query(order?.orderDisplayId, orderModel?.productModel, order?.customerName, order?.phone)">
                      <a>Query</a>
                    </div>
                    <div fxLayoutAlign="center center" class="pnlCancelBtnOrderDetail hide" *ngIf="order.orderStatus == const.ORDER_STATUS.RECEIVED">
                      <a>Cancel</a>
                    </div>
                    <div fxLayoutAlign="center center" class="pnlReturnBtnOrderDetail hide" *ngIf="(order.orderStatus == const.ORDER_STATUS.COMPLETED) && (orderModel.allowReturn)">
                      <a>Return</a>
                    </div>
                    <div fxLayoutAlign="center center" class="pnlRefundBtnOrderDetail hide" *ngIf="order.orderStatus == const.ORDER_STATUS.COMPLETED && orderModel.allowReturn">
                      <a>Exchange</a>
                    </div>
                    <a class="printInvoiceLnk fa fa-print hide"></a>
                    <a class="emailInvoiceLnk fa fa-envelope hide"></a>
                  </div>
                </div>
                <div class="pnlTrackingDetailBdy" [ngClass]="{'hideTab': order.orderStatus == const.ORDER_STATUS.CANCELLED || order.returnedOrExchanged }">
                  <div fxLayout="row" class="statusTrackerItr">
                    <div class="statusTrackerDiv">
                      <div class="statusTracker"></div>
                      <p>Confirmed</p>
                      <div class="innerCircle" [ngClass]="{'green': order.orderStatus == const.ORDER_STATUS.RECEIVED || orderModel.orderStatus == const.ORDER_STATUS.CONFIRMED || order.orderStatus == const.ORDER_STATUS.PACKED
                          || order.orderStatus == const.ORDER_STATUS.DISPATCHED || order.orderStatus == const.ORDER_STATUS.COMPLETED}"></div>
                      <div class="line"></div>
                    </div>
                    <div class="statusTrackerDiv">
                      <div class="statusTracker"></div>
                      <p>Processing</p>
                      <div class="innerCircle" [ngClass]="{'green': order.orderStatus == const.ORDER_STATUS.PACKED  || order.orderStatus == const.ORDER_STATUS.DISPATCHED
                          || order.orderStatus == const.ORDER_STATUS.COMPLETED}"></div>
                      <div class="line"></div>
                    </div>
                    <div class="statusTrackerDiv">
                      <div class="statusTracker"></div>
                      <p>Dispatched</p>
                      <div class="innerCircle" [ngClass]="{'green': order.orderStatus == const.ORDER_STATUS.DISPATCHED || order.orderStatus == const.ORDER_STATUS.COMPLETED}"></div>
                      <div class="line"></div>
                    </div>
                    <div class="statusTrackerDiv">
                      <div class="statusTracker"></div>
                      <p>Delivered</p>
                      <div class="innerCircle" [ngClass]="{'green': order.orderStatus == const.ORDER_STATUS.COMPLETED}"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabPnl" [ngClass]="{'hide': !showRight}">
        <div class="tabBody">
          <div class="pnlShippingAddBdy" *ngIf="order != undefined">
            <p class="lblNameShippingAdd" *ngIf="order?.customerName">{{order?.customerName}},</p>
            <p class="lblNumShippingAdd" *ngIf="order?.phone">{{order?.phone}}</p>
            <p class="lblShippingAddLine1" *ngIf="order?.address1">{{order?.address1}}</p>
            <p class="lblShippingAddLine2" *ngIf="order?.address2">{{order?.address2}}</p>
            <p class="lblShippingAddLine2">{{order?.city}},&nbsp;{{order?.stateName}}&nbsp;-&nbsp;{{order?.pinCode}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="order-details">
    <div class="order-id-details">
        <p class="order-id">Order ID </p>
        <p class="order-date"></p>
    </div>
    <div class="order-price">
      <p class="price"></p>
    </div>
    <div class="order-quantity">
      <p class="qty">Qty </p>
    </div>
    <div class="order-status">
      <p class="status"></p>
    </div>
  </div>

  <div class="order-list">
      <div class="pnlOrderDetailsTrackOrder" >
        
        </div>
  </div> -->

</div>
<div *ngIf="noOrdersFlag" class="order-error">
  <p class="order-err-label">No orders to track corresponding to the email ID and order ID</p>
  <!-- <a class="continue-shop" href="/">Back to Home...</a> -->
  <a class="continue-shop" [routerLink]="''">Back to Home...</a>
</div>