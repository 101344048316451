<footer *ngIf="!checkOutVisibility">
  <div>
    <div class="footer-mb defaultWidth">
      <div class="social-share-container">
        <div *ngIf="socialSettings != undefined" class="social-share">
          <p id="lbl">Engage with us</p>
          <div class="wrapper">
            <a *ngIf="socialSettings.fbPageURL!= ''" href={{socialSettings.fbPageURL}} class="facebook" title="Facebook"><span></span></a>
            <a *ngIf="socialSettings.googlePageURL!= ''" href={{socialSettings.googlePageURL}} class="gPlus" title="gPlus"><span></span></a>
            <a *ngIf="socialSettings.linkedInPageURL!= ''" href={{socialSettings.linkedInPageURL}} class="linkedin"
              title="LinkedIn"><span></span></a>
            <a *ngIf="socialSettings.twitterPageURL!= ''" href={{socialSettings.twitterPageURL}} class="twitter" title="Twitter"><span></span></a>
          </div>
        </div>
      </div>
      <div class="newsletter-container">
        <div *ngIf="showNewsletter" class="newsletter" fxLayout="row">
          <input type="email" id="subsEmail" placeholder="Subscribe to our newsletter" class="tfSubscriptionEmail"
            #inputVal />
          <p class="send-news okLbl" fxLayoutAlign="center center" (click)="subscribeNewsletter(error, inputVal)"><span
              class="fa fa-check"></span></p>
          <span #error class="hide errorLbl"></span>
        </div>
      </div>
    </div>
    <div class="footer-padding  defaultWidth">
      <div class="footer-lower">
        <div *ngIf="quickLinks != undefined" class="quicklinks">
          <p class="heading hide">QUICKLINKS</p>
          <ul>
            <li *ngFor="let quickLink of quickLinks">
              <a *ngIf="quickLink.pageURL.includes('http')" [href]="httpUrl" class="lnkStores" target="_blank">{{quickLink.linkName}}</a>
              <a *ngIf="!quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores">{{quickLink.linkName}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright" fxLayout="row">
    <p class="copyright-txt" fxFlex="70">{{copyRTxt}}</p>
    <div class="power-by" fxLayout="row" fxLayoutAlign="end end" fxFlex="30">
      <p class="power-txt">Powered By</p>
      <a href="http://www.lsnetx.com" target="_blank" class="copy-right-img"><img src="assets/logo.png" title="LSNetX"
          alt="LSNetX"></a>
    </div>
  </div>
</footer>